import React, { useState, useEffect } from 'react';
import { parseMoney } from "../../../utils/utils"
import { Link } from 'react-router-dom';
import { Checkbox } from '../../common/html-elements/'

const ItemlistElmn = ({elmn, changeDisplay}) => {
    const [active, setActive] = useState( (elmn.visible === 'Y') ? true : false)
    
    const ChangeCheck = () => {
        let check = active;
        check = !check;
        setActive(check);
        if (typeof(changeDisplay) === 'function'){
            changeDisplay(elmn.itemId, (check == true) ? 'Y' : 'N' )
        }
    }

    return (
        
    <div key={elmn.itemId}>
        <b>
            {elmn.itemName}
        </b>
        <strong>{parseMoney(elmn.itemPrice)}</strong>
        <img alt={elmn.itemName} width="400px" src={elmn.itemImage}></img>
        <p>
            {`Categoría: ${elmn.category}`}
        </p>
        <div>
            <Checkbox checked={active} onChange={() => ChangeCheck()} />
            <Link className="boton-saber-mas" to={{pathname: `/product/${elmn.itemId}`}} >Detalle</Link>
        </div>
        
    </div>
  
   
  )
}

export default ItemlistElmn