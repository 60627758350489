import React from "react";
import { RadioButtonContainer } from "./styled";
import { COLORS } from "../../../../utils/theme/constants";
import PropTypes from "prop-types";

export const RadioButton = (props) => {
  const { onChange, label, disabled, id, error, name } = props;
  const handleChange = (event) => onChange && onChange(event);
  return (
    <RadioButtonContainer error={error}>
      <input
        type="radio"
        id={`radio-input-${id}`}
        onChange={handleChange}
        onClick={handleChange}
        disabled={disabled}
        name={name}
      />
      <label htmlFor={`radio-input-${id}`}>{label}</label>
    </RadioButtonContainer>
  );
};

RadioButton.defaultProps = {
  color: COLORS.GREEN,
  disabled: false,
  error: false,
};

RadioButton.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  color: PropTypes.string,
  id: PropTypes.string,
  check: PropTypes.bool,
}

export default RadioButton;
