
const isArrayEqual = (a, b) => a.length === b.length && [...a].every(value => b.includes(value))

const getLocalStorage = key => {
    return JSON.parse(localStorage.getItem(key))
}

const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

const removeLocalStorage = key => {
    localStorage.removeItem(key)
}

const parseMoney = (numberValue, numDecimals = 2) => {
    return numberValue.toFixed(numDecimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

const ParseStringToDate = (dateString, separator = '/') => {

    let newDate = dateString.toString().slice(0, 10);

    if (newDate.includes(separator)) {
        const dateSplit = newDate.split(separator);
        newDate = `${("0" + dateSplit[0]).slice(-2)}${separator}${("0" + dateSplit[1]).slice(-2)}${separator}${dateSplit[2].slice(-2)}`;
    }

    return newDate;
}

/**
 * Esta función recibe como primer parámetro funciones convertidoras y como segundo parámetro recibe el valor a procesar.
 *
 *  El orden de ejecución de la función es del exterior hacia adentro.
 *
 *  @descriptor Ejm: pipe(func1, func2, func3)(data)
 */
const pipe = (...fns) => (xs) => fns.reduce((y, f) => f(y), xs);

const isEqualObject = xs1 => xs2 => JSON.stringify(xs1) === JSON.stringify(xs2);

const isEqual = x1 => x2 => x1 === x2;

const isNotEqual = x1 => x2 => x1 !== x2;

/**
 * Recibe un string y retorna un integer o float
 * @param {string} str
 * @return {int} integer
 * */
const parseStringToInt = str => {
    //isFloat
    if (str.includes(".")) return parseFloat(str);
    return parseInt(str)
}

//Validaciones

const inputValidator = (method, data) => {

    const regexAction = {
        'special': /[\W]/g,
        'notnumbers': /[^\d]/g,
        'notletters': /[A-Za-z]/g,
        'numbercomma': /[^\d,]/g,
        'numberdecimal': /[^\d.]/g,
        'onlyNumbers': /\D/g,
        'numbersLetters': /[^\d,\w-]/g, //Numeros, letras, comas y guion medio
        'username': /[^a-zA-Z0-9@_]$/g, //letras, guion bajo, arroba y espacio
        //'password': /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$/,
        'password': /[^a-zA-Z0-9@*&$#+_]$/g, //letras, guion bajo y arroba
        'namesCommon': /[^\w ]/g, //letras, numeros y espacio
        'textCommon': /[^\w ]/g, //letras, numeros y espacio
    }

    return data.replace(regexAction[method], '');

}

const getDate = str => new Date(str);

const getTime = oDate => oDate.getTime();

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const getTimeStamp = (strgDate) => {
    const datum = Date.parse(strgDate);
    return datum / 1000;
}

const fillZeroLeft = (number, width) => {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }
}
export {
    isArrayEqual,
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
    parseMoney,
    ParseStringToDate,
    pipe,
    isEqualObject,
    isEqual,
    isNotEqual,
    parseStringToInt,
    getDate,
    getTime,
    toBase64,
    inputValidator,
    getTimeStamp,
    fillZeroLeft
}

