import React from "react";

export const defaultIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.666 3.33464H15.8327V2.5013C15.8327 2.0013 15.4993 1.66797 14.9993 1.66797C14.4993 1.66797 14.166 2.0013 14.166 2.5013V3.33464H10.8327V2.5013C10.8327 2.0013 10.4993 1.66797 9.99935 1.66797C9.49935 1.66797 9.16602 2.0013 9.16602 2.5013V3.33464H5.83268V2.5013C5.83268 2.0013 5.49935 1.66797 4.99935 1.66797C4.49935 1.66797 4.16602 2.0013 4.16602 2.5013V3.33464H3.33268C2.41602 3.33464 1.66602 4.08464 1.66602 5.0013V15.8346C1.66602 16.7513 2.41602 17.5013 3.33268 17.5013H16.666C17.5827 17.5013 18.3327 16.7513 18.3327 15.8346V5.0013C18.3327 4.08464 17.5827 3.33464 16.666 3.33464ZM5.83268 14.168H4.99935C4.49935 14.168 4.16602 13.8346 4.16602 13.3346C4.16602 12.8346 4.49935 12.5013 4.99935 12.5013H5.83268C6.33268 12.5013 6.66602 12.8346 6.66602 13.3346C6.66602 13.8346 6.33268 14.168 5.83268 14.168ZM5.83268 10.0013H4.99935C4.49935 10.0013 4.16602 9.66797 4.16602 9.16797C4.16602 8.66797 4.49935 8.33464 4.99935 8.33464H5.83268C6.33268 8.33464 6.66602 8.66797 6.66602 9.16797C6.66602 9.66797 6.33268 10.0013 5.83268 10.0013ZM10.416 14.168H9.58268C9.08268 14.168 8.74935 13.8346 8.74935 13.3346C8.74935 12.8346 9.08268 12.5013 9.58268 12.5013H10.416C10.916 12.5013 11.2493 12.8346 11.2493 13.3346C11.2493 13.8346 10.916 14.168 10.416 14.168ZM10.416 10.0013H9.58268C9.08268 10.0013 8.74935 9.66797 8.74935 9.16797C8.74935 8.66797 9.08268 8.33464 9.58268 8.33464H10.416C10.916 8.33464 11.2493 8.66797 11.2493 9.16797C11.2493 9.66797 10.916 10.0013 10.416 10.0013ZM14.9993 14.168H14.166C13.666 14.168 13.3327 13.8346 13.3327 13.3346C13.3327 12.8346 13.666 12.5013 14.166 12.5013H14.9993C15.4993 12.5013 15.8327 12.8346 15.8327 13.3346C15.8327 13.8346 15.4993 14.168 14.9993 14.168ZM14.9993 10.0013H14.166C13.666 10.0013 13.3327 9.66797 13.3327 9.16797C13.3327 8.66797 13.666 8.33464 14.166 8.33464H14.9993C15.4993 8.33464 15.8327 8.66797 15.8327 9.16797C15.8327 9.66797 15.4993 10.0013 14.9993 10.0013Z"
      fill="#F93E4B"
    />
  </svg>
);

export const iconCheck = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3178 3.92748C12.634 4.19503 12.6734 4.66827 12.4059 4.98447L6.90588 11.4845C6.76799 11.6474 6.56719 11.7439 6.3538 11.7497C6.14041 11.7556 5.93465 11.6702 5.78808 11.515L2.95474 8.51498C2.67033 8.21384 2.6839 7.73916 2.98503 7.45476C3.28617 7.17035 3.76085 7.18391 4.04526 7.48505L6.30263 9.87521L11.2608 4.01556C11.5284 3.69935 12.0016 3.65992 12.3178 3.92748Z"
      fill="#83CC5E"
    />
  </svg>
);

export const iconArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7613 5.25318C13.0796 5.58227 13.0796 6.11004 12.7613 6.43913L8.5946 10.7468C8.26808 11.0844 7.73192 11.0844 7.4054 10.7468L3.23874 6.43913C2.92042 6.11004 2.92042 5.58227 3.23874 5.25318C3.56526 4.91561 4.10141 4.91561 4.42793 5.25318L8 8.94615L11.5721 5.25318C11.8986 4.91561 12.4347 4.91561 12.7613 5.25318Z"
      fill="#EF3340"
    />
  </svg>
);

export const iconClose = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2L14 14" stroke="#494F66" strokeLinecap="round" />
    <path
      d="M14.0005 2.00012L2.00048 14.0001"
      stroke="#494F66"
      strokeLinecap="round"
    />
  </svg>
);

export const iconCloseHover = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15.5" stroke="#494F66" />
    <path d="M11 11L20.9996 20.9996" stroke="#494F66" strokeLinecap="round" />
    <path
      d="M21 11.0002L11.0004 20.9998"
      stroke="#494F66"
      strokeLinecap="round"
    />
  </svg>
);

export const iconDownloadUnavailable = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0399 11.06C7.5699 11.59 8.4199 11.59 8.9499 11.06L12.4799 7.53C13.0499 6.97 12.6499 6 11.8499 6H9.9999V3C9.9999 2.45 9.5499 2 8.9999 2H6.9999C6.4499 2 5.9999 2.45 5.9999 3V6H4.1499C3.3499 6 2.9499 6.97 3.5199 7.53L7.0399 11.06Z"
      fill="#FF0000"
    />
    <path
      d="M13 13H3C2.45 13 2 13.45 2 14C2 14.55 2.45 15 3 15H13C13.55 15 14 14.55 14 14C14 13.45 13.55 13 13 13Z"
      fill="#FF0000"
    />
  </svg>
);

export const iconDownload = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0399 11.06C7.5699 11.59 8.4199 11.59 8.9499 11.06L12.4799 7.53C13.0499 6.97 12.6499 6 11.8499 6H9.9999V3C9.9999 2.45 9.5499 2 8.9999 2H6.9999C6.4499 2 5.9999 2.45 5.9999 3V6H4.1499C3.3499 6 2.9499 6.97 3.5199 7.53L7.0399 11.06Z"
      fill="#298925"
    />
    <path
      d="M13 13H3C2.45 13 2 13.45 2 14C2 14.55 2.45 15 3 15H13C13.55 15 14 14.55 14 14C14 13.45 13.55 13 13 13Z"
      fill="#298925"
    />
  </svg>
);
export const iconDownloadHead = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0399 11.06C7.5699 11.59 8.4199 11.59 8.9499 11.06L12.4799 7.53C13.0499 6.97 12.6499 6 11.8499 6H9.9999V3C9.9999 2.45 9.5499 2 8.9999 2H6.9999C6.4499 2 5.9999 2.45 5.9999 3V6H4.1499C3.3499 6 2.9499 6.97 3.5199 7.53L7.0399 11.06Z"
      fill="#6769ff"
    />
    <path
      d="M13 13H3C2.45 13 2 13.45 2 14C2 14.55 2.45 15 3 15H13C13.55 15 14 14.55 14 14C14 13.45 13.55 13 13 13Z"
      fill="#6769ff"
    />
  </svg>
);

export const iconFile = (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 511.999 511.999"
    height="20"
    width="20"
    viewBox="0 0 511.999 511.999"
  >
    <g>
      <path
        d="m422.651 225.765v248.961c0 20.586-16.688 37.273-37.273 37.273h-306.206c-20.586 0-37.273-16.688-37.273-37.273v-390.003c0-20.591 16.692-37.273 37.273-37.273h165.159z"
        fill="#494f66"
      />
      <path
        d="m126.622 464.55c-20.586 0-37.273-16.688-37.273-37.273v-390.004c-.001-20.591 16.691-37.273 37.273-37.273h165.158c28.395 0 178.32 149.924 178.32 178.316v248.961c0 20.586-16.688 37.273-37.273 37.273z"
        fill="#dadada"
      />
      <path
        d="m470.1 178.319v15.767c0-33.195-26.918-60.113-60.113-60.113h-36.587c-20.581 0-37.273-16.692-37.273-37.273v-36.587c0-33.195-26.918-60.113-60.113-60.113h15.767c28.39 0 55.627 11.28 75.701 31.355l71.264 71.264c20.073 20.074 31.354 47.31 31.354 75.7z"
        fill="#494f66"
      />
      <g fill="#494f66">
        <path d="m242.615 284.564v108.975c0 4.701 3.811 8.512 8.512 8.512h57.194c4.701 0 8.512-3.811 8.512-8.512v-108.975h24.315c7.583 0 11.381-9.168 6.019-14.53l-54.331-54.331c-7.241-7.241-18.982-7.241-26.223 0l-54.331 54.331c-5.362 5.362-1.564 14.53 6.019 14.53z" />
        <path d="m213.396 185.797h132.656c9.161 0 16.587-7.426 16.587-16.587v-.456c0-9.161-7.426-16.587-16.587-16.587h-132.656c-9.161 0-16.587 7.426-16.587 16.587v.456c0 9.16 7.426 16.587 16.587 16.587z" />
      </g>
    </g>
  </svg>
);

export const iconFileDropdown = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0176 1.33301H6.67647V3.33063C6.67647 4.44042 5.77308 5.32825 4.67951 5.32825H2.66669V13.3346C2.66669 14.0797 3.26895 14.6663 3.998 14.6663H12.0017C12.7466 14.6663 13.333 14.0639 13.333 13.3346V2.66476C13.3489 1.93547 12.7625 1.33301 12.0176 1.33301ZM10.6863 11.3528H5.34516C4.98064 11.3528 4.67951 11.0516 4.67951 10.687C4.67951 10.3223 4.98064 10.0211 5.34516 10.0211H10.6863C11.0508 10.0211 11.3519 10.3223 11.3519 10.687C11.3519 11.0516 11.0508 11.3528 10.6863 11.3528ZM10.6863 8.68933H5.34516C4.98064 8.68933 4.67951 8.3881 4.67951 8.02346C4.67951 7.65881 4.98064 7.35758 5.34516 7.35758H10.6863C11.0508 7.35758 11.3519 7.65881 11.3519 8.02346C11.3519 8.3881 11.0508 8.68933 10.6863 8.68933Z"
      fill="#676F8F"
    />
    <path
      d="M5.34516 3.34648V1.33301L2.66669 4.01236H4.66366C5.04403 4.01236 5.34516 3.71113 5.34516 3.34648Z"
      fill="#676F8F"
    />
  </svg>
);

export const iconDeleteDropdown = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0002 2.33333C12.0002 1.6 11.4002 1 10.6668 1H5.3335C4.60016 1 4.00016 1.6 4.00016 2.33333H1.3335V5H2.66683V14.3333C2.66683 15.0667 3.26683 15.6667 4.00016 15.6667H12.0002C12.7335 15.6667 13.3335 15.0667 13.3335 14.3333V5H14.6668V2.33333H12.0002ZM5.3335 13C5.3335 13.3733 5.04016 13.6667 4.66683 13.6667C4.2935 13.6667 4.00016 13.3733 4.00016 13V6.33333C4.00016 5.96 4.2935 5.66667 4.66683 5.66667C5.04016 5.66667 5.3335 5.96 5.3335 6.33333V13ZM8.66683 13C8.66683 13.3733 8.3735 13.6667 8.00016 13.6667C7.62683 13.6667 7.3335 13.3733 7.3335 13V6.33333C7.3335 5.96 7.62683 5.66667 8.00016 5.66667C8.3735 5.66667 8.66683 5.96 8.66683 6.33333V13ZM12.0002 13C12.0002 13.3733 11.7068 13.6667 11.3335 13.6667C10.9602 13.6667 10.6668 13.3733 10.6668 13V6.33333C10.6668 5.96 10.9602 5.66667 11.3335 5.66667C11.7068 5.66667 12.0002 5.96 12.0002 6.33333V13Z"
      fill="#6F7DFF"
    />
  </svg>
);

export const iconEdit = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6672 1.33378C14.2939 0.960443 13.8139 0.787109 13.3339 0.787109C12.8539 0.787109 12.3739 0.960443 12.0006 1.33378L6.89391 6.44044C6.74725 6.58711 6.62725 6.77378 6.57391 6.96044L5.69391 9.58711C5.56058 9.97377 5.86725 10.3338 6.22725 10.3338C6.28058 10.3338 6.34725 10.3204 6.41391 10.3071L9.04058 9.42711C9.24058 9.36044 9.41391 9.25378 9.56058 9.10711L14.6672 4.00044C15.4006 3.26711 15.4006 2.06711 14.6672 1.33378Z"
      fill="#676F8F"
    />
    <path
      d="M11.625 11.25C11.625 12.0063 11.0063 12.625 10.25 12.625H4.75C3.99375 12.625 3.375 12.0063 3.375 11.25V5.75C3.375 4.99375 3.99375 4.375 4.75 4.375H6.93625L8.31125 3H4.75C3.2375 3 2 4.2375 2 5.75V11.25C2 12.7625 3.2375 14 4.75 14H10.25C11.7625 14 13 12.7625 13 11.25V7.68875L11.625 9.06375V11.25Z"
      fill="#676F8F"
    />
  </svg>
);

export const icon_success = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="11.9999" r="9.6" fill="#43B748" />
    <path
      d="M7.05884 12.0001L10.353 15.5295L16.9412 8.4707"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const icon_warning = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.133 4.1652L22.1917 17.6375C23.0899 19.1679 21.9173 21.023 20.071 20.9998H3.95361C2.08239 20.9998 0.909763 19.1447 1.80795 17.6375L9.86665 4.1652C10.7898 2.6116 13.2099 2.6116 14.133 4.1652ZM11.7546 7.30298H12.1253C12.667 7.30298 13.1233 7.73582 13.0948 8.24983L12.9237 13.9534C12.9237 14.1698 12.7241 14.3592 12.496 14.3592H11.3839C11.1558 14.3592 10.9562 14.1968 10.9562 13.9534L10.7851 8.24983C10.7566 7.73582 11.2128 7.30298 11.7546 7.30298ZM11.9542 18.5253C11.1558 18.5253 10.5 17.9031 10.5 17.1456C10.5 16.3881 11.1558 15.7659 11.9542 15.7659C12.7526 15.7659 13.4084 16.3881 13.4084 17.1456C13.4369 17.9031 12.7811 18.5253 11.9542 18.5253Z"
      fill="#F76F07"
    />
  </svg>
);

export const icon_danger = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.133 4.1652L22.1917 17.6375C23.0899 19.1679 21.9173 21.023 20.071 20.9998H3.95361C2.08239 20.9998 0.909763 19.1447 1.80795 17.6375L9.86665 4.1652C10.7898 2.6116 13.2099 2.6116 14.133 4.1652ZM11.7546 7.30298H12.1253C12.667 7.30298 13.1233 7.73582 13.0948 8.24983L12.9237 13.9534C12.9237 14.1698 12.7241 14.3592 12.496 14.3592H11.3839C11.1558 14.3592 10.9562 14.1968 10.9562 13.9534L10.7851 8.24983C10.7566 7.73582 11.2128 7.30298 11.7546 7.30298ZM11.9542 18.5253C11.1558 18.5253 10.5 17.9031 10.5 17.1456C10.5 16.3881 11.1558 15.7659 11.9542 15.7659C12.7526 15.7659 13.4084 16.3881 13.4084 17.1456C13.4369 17.9031 12.7811 18.5253 11.9542 18.5253Z"
      fill="#EF3340"
    />
  </svg>
);

export const icon_close_alert = {
  success: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3033 6.69678L6.69674 17.3034"
        stroke="#83CC5E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3033 17.3032L6.69674 6.69662"
        stroke="#83CC5E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  warning: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3033 6.69678L6.69674 17.3034"
        stroke="#F76F07"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3033 17.3032L6.69674 6.69662"
        stroke="#F76F07"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  error: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3033 6.69678L6.69674 17.3034"
        stroke="#FC4E55"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3033 17.3032L6.69674 6.69662"
        stroke="#FC4E55"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};

export const icon_init_alert = {
  success: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="11.9999" r="9.6" fill="#43B748" />
      <path
        d="M7.05884 12.0001L10.353 15.5295L16.9412 8.4707"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  warning: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.133 4.1652L22.1917 17.6375C23.0899 19.1679 21.9173 21.023 20.071 20.9998H3.95361C2.08239 20.9998 0.909763 19.1447 1.80795 17.6375L9.86665 4.1652C10.7898 2.6116 13.2099 2.6116 14.133 4.1652ZM11.7546 7.30298H12.1253C12.667 7.30298 13.1233 7.73582 13.0948 8.24983L12.9237 13.9534C12.9237 14.1698 12.7241 14.3592 12.496 14.3592H11.3839C11.1558 14.3592 10.9562 14.1968 10.9562 13.9534L10.7851 8.24983C10.7566 7.73582 11.2128 7.30298 11.7546 7.30298ZM11.9542 18.5253C11.1558 18.5253 10.5 17.9031 10.5 17.1456C10.5 16.3881 11.1558 15.7659 11.9542 15.7659C12.7526 15.7659 13.4084 16.3881 13.4084 17.1456C13.4369 17.9031 12.7811 18.5253 11.9542 18.5253Z"
        fill="#F76F07"
      />
    </svg>
  ),
  error: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.133 4.1652L22.1917 17.6375C23.0899 19.1679 21.9173 21.023 20.071 20.9998H3.95361C2.08239 20.9998 0.909763 19.1447 1.80795 17.6375L9.86665 4.1652C10.7898 2.6116 13.2099 2.6116 14.133 4.1652ZM11.7546 7.30298H12.1253C12.667 7.30298 13.1233 7.73582 13.0948 8.24983L12.9237 13.9534C12.9237 14.1698 12.7241 14.3592 12.496 14.3592H11.3839C11.1558 14.3592 10.9562 14.1968 10.9562 13.9534L10.7851 8.24983C10.7566 7.73582 11.2128 7.30298 11.7546 7.30298ZM11.9542 18.5253C11.1558 18.5253 10.5 17.9031 10.5 17.1456C10.5 16.3881 11.1558 15.7659 11.9542 15.7659C12.7526 15.7659 13.4084 16.3881 13.4084 17.1456C13.4369 17.9031 12.7811 18.5253 11.9542 18.5253Z"
        fill="#EF3340"
      />
    </svg>
  ),
};

export const IconBottom = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.57095 0.939884C7.80968 1.1867 7.80968 1.58253 7.57095 1.82935L4.44595 5.06012C4.20106 5.31329 3.79894 5.31329 3.55405 5.06012L0.429053 1.82935C0.190315 1.58253 0.190315 1.1867 0.429053 0.939884C0.673943 0.686705 1.07606 0.686705 1.32095 0.939884L4 3.70961L6.67905 0.939884C6.92394 0.686705 7.32606 0.686705 7.57095 0.939884Z" />
    </svg>
  );
};

export const IconCheck = () => {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3178 0.927476C10.634 1.19503 10.6734 1.66827 10.4059 1.98447L4.90588 8.48447C4.76799 8.64743 4.56719 8.74391 4.3538 8.74974C4.14041 8.75556 3.93465 8.67018 3.78808 8.51498L0.954742 5.51498C0.670333 5.21384 0.683896 4.73916 0.985034 4.45476C1.28617 4.17035 1.76085 4.18391 2.04526 4.48505L4.30263 6.87521L9.2608 1.01556C9.52835 0.699354 10.0016 0.659918 10.3178 0.927476Z" />
    </svg>
  );
};

export const IconLeft = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.74682 9.76126C5.41773 10.0796 4.88996 10.0796 4.56087 9.76126L0.253179 5.5946C-0.0843931 5.26808 -0.084393 4.73192 0.25318 4.4054L4.56087 0.238737C4.88996 -0.0795795 5.41773 -0.0795794 5.74682 0.238737C6.08439 0.565257 6.08439 1.10141 5.74682 1.42793L2.05385 5L5.74682 8.57207C6.08439 8.89859 6.08439 9.43474 5.74682 9.76126Z" />
    </svg>
  );
};
export const IconRight = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.253179 0.238737C0.582269 -0.079579 1.11004 -0.079579 1.43913 0.238737L5.74682 4.4054C6.08439 4.73192 6.08439 5.26808 5.74682 5.5946L1.43913 9.76126C1.11004 10.0796 0.582268 10.0796 0.253179 9.76126C-0.0843929 9.43474 -0.0843929 8.89859 0.253179 8.57207L3.94615 5L0.253179 1.42793C-0.0843929 1.10141 -0.0843929 0.565257 0.253179 0.238737Z" />
    </svg>
  );
};
export const IconRightDouble = () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.265662 0.238737C0.610978 -0.079579 1.16477 -0.079579 1.51009 0.238737L6.03017 4.4054C6.38439 4.73192 6.38439 5.26808 6.03017 5.5946L1.51009 9.76126C1.16477 10.0796 0.610978 10.0796 0.265662 9.76126C-0.088554 9.43474 -0.088554 8.89859 0.265662 8.57207L4.14072 5L0.265662 1.42793C-0.0885539 1.10141 -0.0885539 0.565257 0.265662 0.238737Z" />
      <path d="M8.26566 0.238737C8.61098 -0.079579 9.16477 -0.079579 9.51009 0.238737L14.0302 4.4054C14.3844 4.73192 14.3844 5.26808 14.0302 5.5946L9.51009 9.76126C9.16477 10.0796 8.61098 10.0796 8.26566 9.76126C7.91145 9.43474 7.91145 8.89859 8.26566 8.57207L12.1407 5L8.26566 1.42793C7.91145 1.10141 7.91145 0.565257 8.26566 0.238737Z" />
    </svg>
  );
};
export const IconLeftDouble = () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.0302 9.76126C13.6849 10.0796 13.1311 10.0796 12.7858 9.76126L8.26573 5.5946C7.91151 5.26808 7.91151 4.73192 8.26573 4.4054L12.7858 0.238737C13.1311 -0.0795793 13.6849 -0.0795792 14.0302 0.238737C14.3845 0.565257 14.3845 1.10141 14.0302 1.42793L10.1552 5L14.0302 8.57207C14.3845 8.89859 14.3845 9.43474 14.0302 9.76126Z" />
      <path d="M6.03024 9.76126C5.68492 10.0796 5.13113 10.0796 4.78581 9.76126L0.265726 5.5946C-0.0884898 5.26808 -0.0884898 4.73192 0.265727 4.4054L4.78581 0.238737C5.13113 -0.0795793 5.68492 -0.0795792 6.03024 0.238737C6.38445 0.565257 6.38445 1.10141 6.03024 1.42793L2.15518 5L6.03024 8.57207C6.38445 8.89859 6.38445 9.43474 6.03024 9.76126Z" />
    </svg>
  );
};

export const IconExcel = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="18"
    height="18"
    viewBox="0 0 32 32"
    style={{fill: "#a3abcc"}}
  >
    <path d="M 15.875 4 L 15.78125 4.03125 L 4.78125 6.46875 L 4 6.65625 L 4 25.34375 L 4.78125 25.53125 L 15.78125 27.96875 L 15.875 28 L 18 28 L 18 25 L 28 25 L 28 7 L 18 7 L 18 4 Z M 16 6.03125 L 16 25.96875 L 6 23.78125 L 6 8.21875 Z M 18 9 L 26 9 L 26 23 L 18 23 L 18 21 L 20 21 L 20 19 L 18 19 L 18 18 L 20 18 L 20 16 L 18 16 L 18 15 L 20 15 L 20 13 L 18 13 L 18 12 L 20 12 L 20 10 L 18 10 Z M 21 10 L 21 12 L 25 12 L 25 10 Z M 14.15625 11 L 11.875 11.28125 L 10.625 13.96875 C 10.492188 14.355469 10.394531 14.648438 10.34375 14.84375 L 10.3125 14.84375 C 10.234375 14.519531 10.160156 14.238281 10.0625 14 L 9.4375 11.6875 L 7.3125 11.9375 L 7.21875 12 L 9 16 L 7 20 L 9.15625 20.25 L 10.03125 17.78125 C 10.136719 17.46875 10.222656 17.214844 10.25 17.0625 L 10.28125 17.0625 C 10.339844 17.386719 10.378906 17.628906 10.4375 17.75 L 11.78125 20.6875 L 14.21875 21 L 11.5625 15.96875 Z M 21 13 L 21 15 L 25 15 L 25 13 Z M 21 16 L 21 18 L 25 18 L 25 16 Z M 21 19 L 21 21 L 25 21 L 25 19 Z"></path>
  </svg>
);
