import React from "react";
import { BoxInput, ContainerInput, LabelInput, ErrorText } from "./styled";
import { useState } from "react";
import { useCallback, useEffect, useRef } from "react";
import Val from "./validations.js";
import PropTypes from "prop-types";

export const Input = ({
  placeholder,
  width,
  type = "nombre",
  onChange,
  disabled,
  maxLength,
  value,
  errorText,
  opcType = null,
}) => {

  const ref = useRef(null);
  const [valueInput, setValue] = useState(value || "");
  const [fill, setFill] = useState(!!value);
  const [active, setActive] = useState(false);
  const [valid, setValid] = useState(true);
  useEffect(() => {
    setValue(value || "");
    setFill(!!value);
  }, [value]);
  const handleChange = (text) => {
    // let text = e.target.value;
    // const typeInput = type ===  "celular" || type === "documento" || type === "nrodir" || type === "anio" || type === "monto";
    // const typeInput = true;
    if (!disabled && onChange) {
      // console.log("hola", typeInput ? text.replace(/\D/, "") : text);
      onChange(text);
      setValue(text);
      setFill(true);
      setActive(true);
    }
    validateInput(text);
  };

  useEffect(() => {
    if (valueInput.length > 0) {
      setValid(Val.validarcampos(type, valueInput, opcType));
    }
  }, []);

  const validateInput = (text) => {
    setValid(Val.validarcampos(type, text, opcType));
  };

  //Comentado por ahora para luego revisar - tecla escape que cruzaba el texto del input con el placeholder
  const escListener = useCallback((e) => {

    /*
     if (e.key === "Escape") {
       console.log('tecla escape',valueInput)
       setActive(false);
       if (valueInput === "") {
         setFill(false);
       }
     }
     */
  }, []);

  const clickOutListener = useCallback(
    (e) => {
      if (!(ref.current)?.contains(e.target)) {
        setActive(false);
        if (valueInput === "") {
          setFill(false);
        }
      }
    },
    [ref.current, valueInput]
  );

  useEffect(() => {
    document.addEventListener("click", clickOutListener);
    document.addEventListener("keyup", escListener);

    return () => {
      document.removeEventListener("click", clickOutListener);
      document.removeEventListener("keyup", escListener);
    };
  }, [valueInput]);

  const handleClick = () => {
    if (!disabled) {
      setActive(true);
    }
  };

  return (
    <>
      <ContainerInput
        ref={ref}
        filled={fill}
        width={width}
        active={active}
        disabled={disabled}
        valid={valid}
      >
        <LabelInput active={active} filled={fill}>
          {placeholder}{" "}
        </LabelInput>
        <BoxInput
          maxLength={maxLength}
          value={valueInput}
          onClick={() => handleClick()}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
          type={type}
        />
      </ContainerInput>
      {!valid && !active && <ErrorText>{errorText}</ErrorText>}
    </>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["text", "number", "decimal", "alphaNumeric", "password", "email"]),
  opcType: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.any,
  errorText: PropTypes.string
}

export default Input;
