import React from "react";
import Container from './styled';
import PropTypes from 'prop-types';

export const Button = props => {
  const { size, type, children, onClick, className, disabled } = props;

  const propSize = size ? size : 'default';
  const propType = type ? type : 'primary';

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  }

  return (
    <Container
      propSize={propSize}
      propType={propType}
      className={className}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </Container>
  )
};

Button.defaultProps = {
  size: 'default',
  type: 'primary'
}

Button.propTypes = {
  size: PropTypes.oneOf(["small", "default", "large", "xsmall"]),
  type: PropTypes.oneOf(["primary", "secondary", "link", "order"]),
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default Button;
