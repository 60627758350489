import { type } from '@testing-library/user-event/dist/type';
import React, { useState, useEffect } from 'react';
import './style.sass'
import arrowl from '../../../assets/menu/arrow-left.png'
import arrowr from '../../../assets/menu/arrow-rigth.png'

const MenulistCategory = ({catgList, catgSelected}) => {
    const [listCtrl, setListCtrl] = useState(0)
    const categories = catgList;

    const handleChangeCategory = (changeType) => {
        console.log('changeType: ', changeType);
        let newListCtrl = listCtrl;
        if(changeType === 'B'){
            if(listCtrl > 0){
                newListCtrl -= 1;   
            }
        }
        if(changeType === 'F'){
            if(listCtrl < categories.length - 1){
                newListCtrl += 1
            }
        }
        catgSelected('category',{value: categories[newListCtrl]?.value, text: categories[newListCtrl]?.text})
        setListCtrl(newListCtrl)
    }
    
    return (

    <div className="categories">
        <div className='catgLeft' onClick={() => handleChangeCategory('B')}><img src={arrowl} alt='' /></div>
        <div className='catgTitle'>
            <span>Selecciona una categoria</span>
            <strong>{categories[listCtrl]?.text}</strong>
        </div>
        <div className='catgRight' onClick={() => handleChangeCategory('F')}><img src={arrowr} alt='' /></div>
    </div>       
  )
}

export default MenulistCategory