import React, { useState, useEffect } from 'react';
import { Input, Button } from "../../common/html-elements";
import { Alert } from "../../common/html-accesories";
import { Spin } from "../../common/html-accesories";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import './style.sass';

const Login = () => {

    const [loginData, setLoginData] = useState({
        email: "", password: ""
    })
    const [errorMessage, setErrorMessage] = useState("");
    //== alert section
    const [showAlert, setShowAlert] = useState({
        show: false,
        title: "",
        message: "",
        type: "",
      });
    
    const resetAlert = () => {
        setShowAlert({
          show: false,
          title: "",
          message: "",
          type: "",
        });
      };

    const resetMessage = (sec) =>
        setTimeout(() => {
        resetAlert();
    }, sec * 1000);
    //== fin alert section
    const [showSpin, setShowSpin] = useState(false)
    const navigate = useNavigate();
    
    const [rspnUserData, rqstUserData] = useFetch(
        `signin/user/`,
        loginData,
        'POST',
        false,
        () => {
        let rspnData = rspnUserData.data;
            if(rspnData.auth === true){
                localStorage.setItem("logn",JSON.stringify(rspnData))
                navigate("/")
            }else{

            }
        setShowSpin(false);
    })

    useEffect(() => {
        let rspnError =  rspnUserData.error.response;
        setShowSpin(false);
        let friendlyMessage = ''

        if (rspnError?.status == '500'){
            friendlyMessage = rspnError?.data
        }else if (rspnError?.status == '401'){
            friendlyMessage = "Contraseña incorrecta"
        }else{
            console.log(rspnError);
        }
        if(friendlyMessage != ''){
            setShowAlert({
                show: true,
                title: "Lo sentimos",
                message: friendlyMessage,
                type: "error",  
            });    
        }
    }, [rspnUserData.error])
    
    const handleLogin = () => {
        setShowSpin(true);
        rqstUserData();
    }
    
    const handlePressEnter = (e) => {
        console.log("handler");
        if (e.key === "Enter") {
            console.log("enter key");
        }
    };

    //== handle data section
    const handleChangeData = (name, value) => {
        setLoginData((prevItem) => ({ ...prevItem, [name]: value }));
    };
    
  return (
    <div>
        {showSpin && (
            <Spin />
        )}
        {showAlert.show && (
            <Alert
            title={showAlert.title}
            message={showAlert.message}
            type={showAlert.type}
            hasBg={true}
            position="mid-mid"
            handleClose={() => resetAlert()}
            />
        )}
        
        <section id="titulo">
            <h4 className="title-06">Ingreso a su cuenta</h4>
        </section>
        <section>
            <Input
            placeholder="Correo electrónico"
            value={loginData.email}
            maxLength={100}
            onChange={(text) => handleChangeData("email", text)}
            type="email"
            />
            <Input
            placeholder="Contraseña"
            value={loginData.password}
            maxLength={20}
            onChange={(text) => handleChangeData("password", text)}
            type="password"
            />
            {errorMessage && (
            <div className="formulario_error">
                <p className="color-red title-09">{errorMessage}</p>
            </div>
            )}
        </section>
        <section id="acciones">
            <Button type="primary" size="large" onClick={() => handleLogin()}> Ingresar </Button>
        </section>        

  </div>
  )
}

export default Login