import React from "react";
import {
  Options,
  ContainerOptions,
  ContainerInput,
  SelectContainer,
  OptionSelected,
} from "./styled";
import { useEffect, useState, useRef, useCallback } from "react";
import { Icon } from "../icon";
import { COLORS } from "../../../../utils/theme/constants";
import PropTypes from "prop-types";

export const Select = (props) => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const {
    placeholder,
    width,
    options,
    onSelect,
    selectedItem,
    disabled,
  } = props;

  useEffect(() => {
    if (selectedItem) {
      setOptionSelected(selectedItem);
    }
  }, [selectedItem]);

  const selectOption = (option) => {
    setOptionSelected(option);
    setShowOptions(false);
    onSelect && onSelect(option);
  };

  const escListener = useCallback((e) => {
    if (e.key === "Escape") {
      setShowOptions(false);
    }
  }, []);

  const clickOutListener = useCallback(
    (e) => {
      if (!(ref.current)?.contains(e.target)) {
        setShowOptions(false);
      }
    },
    [ref.current]
  );

  useEffect(() => {
    document.addEventListener("click", clickOutListener);
    document.addEventListener("keyup", escListener);
    return () => {
      document.removeEventListener("click", clickOutListener);
      document.removeEventListener("keyup", escListener);
    };
  }, []);

  const handleClick = () => {
    if (!disabled) {
      setShowOptions(!showOptions);
    }
  };

  return (
    <SelectContainer width={width} active={showOptions} ref={ref}>
      <ContainerInput
        onClick={handleClick}
        active={showOptions}
        fill={optionSelected}
        disabled={disabled}
      >
        <div>
          <span>{placeholder}</span>
          {optionSelected && (
            <OptionSelected disabled={disabled}>
              {optionSelected && optionSelected.text}
            </OptionSelected>
          )}
        </div>
        {<Icon
          name={showOptions ? "gl_arrow_up" : "gl_arrow_down"}
          width="16px"
          height="16px"
          fill={disabled ? COLORS.GRAY_4 : COLORS.RED}
          />}
      </ContainerInput>
      {/* { showOptions && */}
      <ContainerOptions showOptions={showOptions}>
        {options &&
          options.map((option) => (
            <Options key={option.value} onClick={() => selectOption(option)}>
              {option.text}
              {optionSelected && optionSelected.value === option.value && (
                <Icon
                  name={"gl_check"}
                  width="16"
                  height="16px"
                  //fill={COLORS.GREEN}
                  fill="green"
                />
              )}
            </Options>
          ))}
      </ContainerOptions>
      {/* } */}
    </SelectContainer>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.string, text: PropTypes.string})),
  onSelect: PropTypes.func,
  selectedItem: PropTypes.shape({value: PropTypes.any, text: PropTypes.any}),
  disabled: PropTypes.bool
}

export default Select;
