import { createSlice } from '@reduxjs/toolkit';

const shopingCartSlice = createSlice ({
    name: 'shopCartSlice',
    initialState: [],
    reducers: {
        addProduct: (state, action) => {
            
            //const newProduct = {
            //    category: action.payload.category,
            //    quantity: 1,
            //    itemId: action.payload.itemId,
            //    itemName: action.payload.itemName
            //}
            console.log('newProd-payload: ', action.payload);
            state.push(action.payload)
        },
        deleteProduct: (state) => {

        }
    }
});

export const { addProduct, deleteProduct} = shopingCartSlice.actions;
export default shopingCartSlice.reducer;

