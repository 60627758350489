module.exports = {
  gl_arrow_left: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M10.747 12.761a.856.856 0 01-1.186 0L5.253 8.595a.825.825 0 010-1.19L9.561 3.24a.856.856 0 011.186 0 .825.825 0 010 1.189L7.054 8l3.693 3.572a.825.825 0 010 1.19z",
          fill: "#A3ABCC",
        },
      },
    ],
  },
  gl_add: {
    viewBox: "0 0 24 24",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M12 3c5 0 9 4 9 9s-4 9-9 9-9-4-9-9 4-9 9-9zm0-1C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4 9.5h-3.5V8c0-.3-.2-.5-.5-.5s-.5.2-.5.5v3.5H8c-.3 0-.5.2-.5.5s.2.5.5.5h3.5V16c0 .3.2.5.5.5s.5-.2.5-.5v-3.5H16c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z",
          fill: "#494f66",
        },
      },
    ],
  },
  gl_arrow_down: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M12.761 5.253a.856.856 0 010 1.186l-4.166 4.308a.825.825 0 01-1.19 0L3.24 6.439a.856.856 0 010-1.186.825.825 0 011.189 0L8 8.946l3.572-3.693a.825.825 0 011.19 0z",
          fill: "#A3ABCC",
        },
      },
    ],
  },
  gl_close: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M1.646 1.646a.5.5 0 01.708 0L8 7.293l5.647-5.646a.5.5 0 11.707.707L8.707 8l5.647 5.646a.5.5 0 01-.707.708L8 8.707l-5.646 5.647a.5.5 0 01-.707-.707L7.293 8 1.646 2.354a.5.5 0 010-.708z",
          fill: "#494F66",
        },
      },
    ],
  },
  gl_arrow_right: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M5.253 3.239a.856.856 0 011.186 0l4.308 4.166a.825.825 0 010 1.19L6.439 12.76a.856.856 0 01-1.186 0 .825.825 0 010-1.189L8.946 8 5.253 4.428a.825.825 0 010-1.19z",
          fill: "#A3ABCC",
        },
      },
    ],
  },
  gl_arrow_up: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M3.239 10.747a.856.856 0 010-1.186l4.166-4.308a.825.825 0 011.19 0l4.166 4.308a.856.856 0 010 1.186.825.825 0 01-1.189 0L8 7.054l-3.572 3.693a.825.825 0 01-1.19 0z",
          fill: "#A3ABCC",
        },
      },
    ],
  },
  gl_correct: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.8 6.2l-4.4 4.7c-.1.2-.3.2-.5.2s-.4-.1-.5-.2L4.2 8.5c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l1.6 1.8 3.8-4.1c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1z",
          fill: "#494f66",
        },
      },
    ],
  },
  gl_check: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M12.318 3.927a.75.75 0 01.088 1.057l-5.5 6.5a.75.75 0 01-1.118.031l-2.833-3a.75.75 0 111.09-1.03l2.258 2.39 4.958-5.86a.75.75 0 011.057-.088z",
          fill: "#676F8F",
        },
      },
    ],
  },
  gl_phone: {
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M9.789 13.39c-1.934-2.654-2.641-4.166-.861-5.363 1.53-1.03 1.43-1.144.052-3.7C7.708 1.965 7.178 1.354 5.427 2.71c-3.895 2.6-2.859 6.82 1.378 12.632 4.236 5.812 8.166 8.193 11.938 5.636 1.925-1.117 1.51-1.804-.366-3.76-2.03-2.117-2.127-2.222-3.632-1.21-1.753 1.18-3.022.033-4.956-2.62z",
          fill: "#494F66",
        },
      },
    ],
  },
  gl_discount: {
    viewBox: "0 0 20 20",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M10 2c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM6 7.8c0-.3 0-.5.1-.8.1-.2.2-.4.4-.6.2-.1.3-.3.6-.4.4-.2 1-.2 1.4 0 .2.1.4.3.5.4.2.2.3.4.4.6.1.2.1.5.1.8 0 .3-.1.6-.2.8 0 .2-.1.4-.3.6-.2.2-.3.3-.6.4-.2.1-.4.1-.6.1-.2 0-.5 0-.7-.1-.3-.1-.4-.2-.6-.4-.2-.2-.3-.4-.4-.6-.1-.2-.1-.5-.1-.8zm2.2 5.6l-.2.2c-.1.1-.2.1-.3.1H6.4l5.4-7 .2-.2c.1-.1.2-.1.3-.1h1.2l-5.3 7zM14 13c-.1.2-.2.4-.4.6-.2.2-.4.3-.6.4-.2.1-.4.1-.7.1s-.5 0-.7-.1c-.2-.1-.4-.2-.6-.4-.2-.2-.3-.4-.4-.6-.1-.2-.1-.5-.1-.8 0-.3 0-.6.1-.8.1-.2.2-.4.4-.6.2-.2.3-.3.6-.4.4-.2 1-.2 1.4 0 .2.1.4.2.6.4.2.2.3.4.4.6.1.2.1.5.1.8 0 .3-.1.6-.1.8zm-1.7-1.6c-.3 0-.5.2-.5.5v.6c0 .3.2.5.5.5s.5-.2.5-.5v-.6c0-.3-.2-.5-.5-.5zM7.8 8.6c.3 0 .5-.2.5-.5v-.6c0-.3-.2-.5-.5-.5s-.5.2-.5.5v.6c0 .3.2.5.5.5z",
          fill: "#494f66",
        },
      },
    ],
  },
  gl_delete: {
    viewBox: "0 0 20 20",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          class: "st0",
          d:
            "M14.2 4.4c0-.8-.6-1.4-1.4-1.4H7.2c-.8 0-1.4.6-1.4 1.4H3v2.7h1.4v9.5c0 .8.6 1.4 1.4 1.4h8.4c.8 0 1.4-.6 1.4-1.4V7.1H17V4.4h-2.8zm-7 10.9c0 .4-.3.7-.7.7s-.7-.3-.7-.7V8.5c0-.4.3-.7.7-.7s.7.3.7.7v6.8zm3.5 0c0 .4-.3.7-.7.7s-.7-.3-.7-.7V8.5c0-.4.3-.7.7-.7s.7.3.7.7v6.8zm3.5 0c0 .4-.3.7-.7.7s-.7-.3-.7-.7V8.5c0-.4.3-.7.7-.7s.7.3.7.7v6.8z",
        },
      },
    ],
  },
  gl_error: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3 9.9c.3.3.3.8 0 1.1s-.8.3-1.1 0L8 9.1 6.1 11c-.3.3-.8.3-1.1 0-.3-.3-.3-.8 0-1.1L6.9 8 5 6.1c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0L8 6.9 9.9 5c.3-.3.8-.3 1.1 0s.3.8 0 1.1L9.1 8 11 9.9z",
          fill: "#494f66",
        },
      },
    ],
  },
  gl_document: {
    viewBox: "0 0 20 20",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M14.5 2h-6v2.4c0 1.3-1 2.4-2.2 2.4H4v9.6c0 .9.7 1.6 1.5 1.6h9c.8 0 1.5-.7 1.5-1.6V3.6c0-.9-.6-1.6-1.5-1.6zM13 14H7c-.4 0-.7-.4-.7-.8s.3-.8.7-.8h6c.4 0 .7.4.7.8.1.5-.3.8-.7.8zm0-3.2H7c-.4 0-.7-.4-.7-.8s.3-.8.7-.8h6c.4 0 .7.4.7.8.1.5-.3.8-.7.8zM7 4.4V2L4 5.2h2.2c.5 0 .8-.3.8-.8z",
          fill: "#494f66",
        },
      },
    ],
  },
  icon_close_modal: {
    viewBox: "0 0 32 32",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M16 32C7.2 32 0 24.8 0 16S7.2 0 16 0s16 7.2 16 16-7.2 16-16 16zm0-31C7.7 1 1 7.7 1 16s6.7 15 15 15 15-6.7 15-15S24.3 1 16 1zm5.4 20.4c.2-.2.2-.5 0-.7l-10-10c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l10 10c.1.1.2.1.4.1s.2 0 .3-.1zm-10 0l10-10c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-10 10c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.2 0 .3-.1z",
          fill: "#ef3340",
        },
      },
    ],
  },
  gl_warning: {
    viewBox: "0 0 16 16",
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M14.8 11.8l-5.4-9c-.6-1-2.2-1-2.8 0l-5.4 9c-.6 1 .2 2.2 1.4 2.2h10.7c1.3 0 2.1-1.2 1.5-2.2zm-7-6.9H8c.4 0 .7.3.6.6v3.8c0 .1-.1.3-.3.3h-.7c-.2 0-.3-.1-.3-.3l-.1-3.8c0-.3.3-.6.6-.6zm.2 7.5c-.5 0-1-.4-1-.9s.4-.9 1-.9c.5 0 1 .4 1 .9 0 .4-.5.9-1 .9z",
          fill: "#494f66",
        },
      },
    ],
  },
  gl_search: {
    width: "20",
    height: "20",
    svgData: [
      {
        name: "path",
        value: {
          d:
            "M18.233 15.834l-2.6-2.8c.8-1.2 1.2-2.6 1.2-4.2 0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8c1.6 0 3-.4 4.2-1.2l2.8 2.8c.4.2.8.4 1.2.4.4 0 .8-.2 1.2-.6.8-.6.8-1.8 0-2.4zm-9.4-1c-3.4 0-6-2.6-6-6s2.6-6 6-6 6 2.6 6 6-2.6 6-6 6z",
          fill: "#494F66",
        },
      },
    ],
  },
  
};
