import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../../utils/theme/constants";
import { IconCheck } from "../icon";
import PropTypes from "prop-types";

const CheckboxDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid ${COLORS.BLUE_4};
  border-radius: 5px;
  transition: 0.1s ease;
  &:hover {
    cursor: pointer;
  }
  &.checked {
    background-color: ${COLORS.BLUE};
    border: 1px solid ${COLORS.BLUE_3};
  }
  svg {
    fill: white;
  }
`;

export const Checkbox = (props) => {
  const { className = "", id, checked, onChange } = props;

  return (
    <CheckboxDiv
      id={id}
      className={`${className} ${checked && "checked"}`.trim()}
      onClick={onChange}
    >
      <IconCheck />
    </CheckboxDiv>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.oneOf([PropTypes.string, undefined]),
  onChange: PropTypes.func
}

export default Checkbox;
