import React, { useState, useEffect } from 'react';
import { Alert } from "../../common/html-accesories";
import CategorylistElmn from "../categorylistElmn";
import useFetch from "../../../hooks/useFetch";
import "./style.sass";
import { Input, Select, Button } from "../../common/html-elements";
import { Spin } from "../../common/html-accesories";
import Navbar from '../navbar';
import { useNavigate } from 'react-router-dom';

const Categorylist = () => {
    const [categories, setCategories] = useState([]);
    const [groups, setGroups] = useState([]);
    const bussInfo = JSON.parse(localStorage.getItem('buss'));
    let bussCode = (bussInfo?.bussinesCode != undefined) ? bussInfo?.bussinesCode : '';
    const navigate = useNavigate();
    
    const [categorySituation, setCategorySituation] = useState(
        {
            categoryId: 0,
            categoryOwner: bussCode,
            categorySituation: 0
        }
    )

    const [showSpin, setShowSpin] = useState(true)

    const [rspnGetCategories, rqstGetCategories] = useFetch(
        `category?owner=${bussCode}`,
        undefined,
        'GET',
        true,
        () => {
        
            let rspnData = rspnGetCategories.data;
            setCategories(
                rspnData[0].filter((elmn) =>
                    elmn.categoryId != 0
                )
            );
            setGroups(
                rspnData[1]
            );

            setShowSpin(false)
        }
    )
    
    const [rspnUpdateSituation, rqstUpdateSituation] = useFetch(
        `category/${categorySituation.categoryId}`,
        categorySituation,
        'PATCH',
        false,
        () => {

            let rspnData = rspnUpdateSituation.data;
            let rslt = rspnData.b_rtrn_rslt.split("-")[0]
            
            //if (rslt === "OK"){
                
            //}else if(rslt === "NO"){

            //}
        }
    )

    const handleChangeDisplay = (categoryId, situation) => {
        setCategorySituation((prevDisplay) => ({...prevDisplay, 'categoryId': categoryId, 'categorySituation': situation}))
    }

    useEffect(() => {
        rqstUpdateSituation();
    }, [categorySituation])

    return (
        <>
        <Navbar/>
        {showSpin &&
            <Spin />
        }
        <section id="actions">
            <Button type="secondary" size="large" onClick={() => {
                setShowSpin(true);
                rqstGetCategories();
                }}> Recargar </Button>
            <Button type="primary" size="large" onClick={() => {
                navigate('/category');
                }}> Agregar </Button>
        </section>  
        <section id="categoria">
            {
                categories.map((item) => (
                    <CategorylistElmn elmn={item} changeDisplay={(i,v) => handleChangeDisplay(i,v)} />
                ))
            }
        </section>
    </>
  )
}

export default Categorylist