import React, { useState, useEffect } from 'react';
import { Input, Select, Button } from "../../common/html-elements";
import { Alert } from "../../common/html-accesories";
import { inputValidator, fillZeroLeft } from "../../../utils/utils";
import { Spin } from "../../common/html-accesories";
import useFetch from "../../../hooks/useFetch";
import { useParams } from 'react-router-dom';
import Imageupld from '../../imgeupld';
import Navbar from '../navbar';
import { useNavigate } from 'react-router-dom';
import './style.sass';

let categoryGroups = [];
let itemImageBckt = 'https://fast-menu.s3.amazonaws.com/';

const Item = () => {
    const { itemId } = useParams();
    const [itemCtrl, setItemCtrl] = useState((itemId != undefined) ? itemId : 0);
    const bussInfo = JSON.parse(localStorage.getItem('buss'));
    const bussCode = (bussInfo?.bussinesCode != undefined) ? bussInfo?.bussinesCode : '';
    const navigate = useNavigate();
    const [itemSaveMode, setItemSaveMode] = useState({
        apiRoute: "item",
        apiMethod: "POST",
        itemViewMode: 'N',
        itemImageKey: ''
    });
    const [itemData, setItemData] = useState({
        itemOwner: bussCode, code: "", category: 0, itemName: "", features: "", description: "", ingredients: "", itemImage: itemImageBckt, itemPrice: 0, priceOff: 0, categoryGroup: 0,itemState: 1
    })
    const [errorMessage, setErrorMessage] = useState("");
    //== alert section
    const [showAlert, setShowAlert] = useState({
        show: false,
        title: "",
        message: "",
        type: "",
      });
    
    const resetAlert = () => {
        setShowAlert({
          show: false,
          title: "",
          message: "",
          type: "",
        });
      };

    const resetMessage = (sec) =>
        setTimeout(() => {
        resetAlert();
    }, sec * 1000);
    //== fin alert section
    const [showSpin, setShowSpin] = useState(true)
    //== category section
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryGroupOptions, setCategoryGroupOptions] = useState([
        {
            value: 0,
            text: 'UNICO'    
        }
    ]);
    const [selectedCategoryOption, setSelectedCategoryOption] = useState({
        value: 0,
        text: '—SELECCIONE—',
    })
    const [selectedCategoryGroupOption, setSelectedCategoryGroupOption] = useState({
        value: 0,
        text: 'UNICO',
    })

    const [rspnGetCategory, rqstGetCategory] = useFetch(
        `category?owner=${bussCode}`,
        undefined,
        'GET',
        true,
        () => {
        
        let rspnData = rspnGetCategory.data;
        
        setCategoryOptions(
            rspnData[0].map((item) => ({
                value: item.categoryId,
                text: item.categoryName.toUpperCase(),
            }))
        );

        categoryGroups = rspnData[1];
        setShowSpin(false);

        }
        
    )

    useEffect(() => {
        if(categoryOptions.length > 0){
            setShowSpin(true);
            rqstGetItem();
        }
    }, [categoryOptions])

    //== fin category section

    const [rspnGetItem, rqstGetItem] = useFetch(
        `item/${itemCtrl}?owner=${bussCode}`,
        undefined,
        'GET',
        false,
        () => {
        
        let rspnData = rspnGetItem.data;

        if(itemCtrl > 0 && rspnData){
            rspnData = rspnData[0];
            
            setItemData(() => ({
                itemOwner: bussCode,
                code: rspnData.code,category: rspnData.category,itemName: rspnData.itemName,
                features: rspnData.features,description: rspnData.description,ingredients:rspnData.ingredients,
                itemImage: rspnData.itemImage,itemPrice: rspnData.itemPrice,priceOff: rspnData.priceOff,
                categoryGroup: rspnData.categoryGroup,itemState: rspnData.itemState
            }));
            
            setSelectedCategoryOption(
                categoryOptions.filter((elmn) => elmn.value === rspnData.category)[0]
            )

            setCategoryGroupOptions(
                categoryGroups.filter((elmn) => 
                    elmn.categoryId == 0 || elmn.categoryId == rspnData.category
                ).map((item) => ({
                    value: item.groupId,
                    text: item.groupName.toUpperCase(),
                }))
            )

            setSelectedCategoryGroupOption(
                categoryGroups.filter((elmn) => 
                    elmn.groupId == rspnData.categoryGroup
                ).map((item) => ({
                    value: item.groupId,
                    text: item.groupName.toUpperCase(),
                }))[0]
            )

            setItemSaveMode({
                apiRoute:`item/${rspnData.itemId}`,
                apiMethod: "PUT",
                itemViewMode: "E",
                itemImageKey: `P${fillZeroLeft(rspnData.itemId,8)}`,
            })
        }
        setShowSpin(false)
        }
    )
/*
    useEffect(() => {
        setItemData((prevItem) => ({ ...prevItem, itemImage: itemSaveMode.itemImageKey === '' ? '' : `${itemImageBckt}${itemSaveMode.itemImageKey}.jpeg`}));
    }, [itemSaveMode.itemImageKey])
 */       
    const [rspnSaveItem, rqstSaveItem] = useFetch(
        itemSaveMode.apiRoute,
        itemData,
        itemSaveMode.apiMethod,
        false,
        () => {
            setShowSpin(false);

            let rspnData = rspnSaveItem.data;
            let rslt = rspnData.b_rtrn_rslt.split("-")[0]
            let rsltItemCtrl = rspnData.b_rtrn_rslt.split("-")[1]
            if (rslt === "OK"){
                
                setItemCtrl(rsltItemCtrl);

                setItemSaveMode({
                    apiRoute: "item/" + rspnData.b_rtrn_rslt.split("-")[1],
                    apiMethod: "PUT",
                    itemImageKey: `P${fillZeroLeft(rsltItemCtrl,8)}`,
                    itemViewMode: 'N', 
                })
                
                setShowAlert({
                    show: true,
                    title: "Registro de productos",
                    message: itemSaveMode.apiRoute.indexOf("/") > 0 ? "Registro actualizado." : "Registro completado.",
                    type: "success",
                })
            }else if(rslt === "NO"){
                setShowAlert({
                    show: true,
                    title: "No se completó el proceso.",
                    message: rspnData.b_rtrn_rslt,//.split("-")[1],
                    type: "warning",
                })
            }
            
            resetMessage(5);
            
        }
    )
    
    const saveThisForm = () => {
        setShowSpin(true);
        rqstSaveItem();
    }

    const cleanThisForm = () => {
        setItemData({
            itemOwner: bussCode, code: "", category: 0, itemName: "", features: "", description: "", ingredients: "", itemImage: itemImageBckt, itemPrice: 0, priceOff: 0, categoryGroup: 0,itemState: 1
        });
        setItemSaveMode({
            apiRoute: "item",
            apiMethod: "POST",
            itemImageRoot: 'https://fast-menu.s3.amazonaws.com/fast-menu.jpg',
            itemImageKey: ''    
        });
        setSelectedCategoryOption({
            value: 0,
            text: '—SELECCIONE—',
        })
        setSelectedCategoryGroupOption({
            value: 0,
            text: 'UNICO',
        })
        navigate('/product')
    }
    
    const handlePressEnter = (e) => {
        console.log("handler");
        if (e.key === "Enter") {
            console.log("enter key");
        }
    };
    const confirmUpload = () => {
        setShowAlert({
            show: true,
            title: "Imagen del producto.",
            message: "Se completó la subida de imagen.",
            type: "success",
        })
        resetMessage(5);
    };

    //== handle data section
    const handleChangeData = (name, value) => {
        setItemData((prevItem) => ({ ...prevItem, [name]: value }));
    };

    const handleSelectOption = (name, dataValue) => {
        let {value, text} = dataValue;
        setItemData((prevItem) => ({ ...prevItem, [name]: value }));
        
        if(name === 'category'){  
            setSelectedCategoryOption({ value, text });
            setCategoryGroupOptions(
                categoryGroups.filter((elmn) => 
                    elmn.categoryId == 0 || elmn.categoryId == value
                ).map((item) => ({
                    value: item.groupId,
                    text: item.groupName.toUpperCase(),
                }))
            )
            setSelectedCategoryGroupOption({ value: 0, text: 'UNICO' })
        }
        if(name === 'categoryGroup'){
            setSelectedCategoryGroupOption({ value, text })
        }
        
    };
    
  return (
    <div>
        <Navbar/>
        {showSpin && (
            <Spin />
        )}
        {showAlert.show && (
            <Alert
            title={showAlert.title}
            message={showAlert.message}
            type={showAlert.type}
            hasBg={true}
            position="mid-mid"
            handleClose={() => resetAlert()}
            />
        )}
        
        <section id="titulo">
            <h4 className="title-06">Detalle del producto</h4>
        </section>
        <section>
            <Select
            placeholder="Categoría"
            options={categoryOptions}
            onSelect={option => handleSelectOption('category', {value: option.value, text: option.text})}
            selectedItem={selectedCategoryOption}
            />
            <Input
            placeholder="Codigo"
            value={itemData.code}
            maxLength={5}
            onChange={(text) => handleChangeData("code", text)}
            />
            <Input
            placeholder="Nombre"
            value={itemData.itemName}
            maxLength={100}
            onChange={(text) => handleChangeData("itemName", text)}
            />
            <Input
            placeholder="Caracteristicas"
            value={itemData.features}
            maxLength={100}
            onChange={(text) => handleChangeData("features", text)}
            />
            <Input
            placeholder="Descripción"
            value={itemData.description}
            maxLength={300}
            onChange={(text) => handleChangeData("description", text)}
            />
            <Input
            placeholder="Ingredientes básicos"
            value={itemData.ingredients}
            maxLength={150}
            onChange={(text) => handleChangeData("ingredients", text)}
            />
            <Input
            placeholder="Precio"
            value={() => inputValidator('numberdecimal', itemData.itemPrice.toString())}
            maxLength={6}
            type="decimal"
            onChange={(text) => handleChangeData("itemPrice", inputValidator('numberdecimal',text))}
            />
            <Input
            placeholder="Precio Oferta"
            value={() => inputValidator('numberdecimal', itemData.priceOff.toString())}
            maxLength={6}
            onChange={(text) => handleChangeData("priceOff", inputValidator('numberdecimal',text))}
            />
            <Select
            placeholder="Grupo"
            options={categoryGroupOptions}
            onSelect={option => handleSelectOption('categoryGroup', {value: option.value, text: option.text})}
            selectedItem={selectedCategoryGroupOption}
            />

            {errorMessage && (
            <div className="formulario_error">
                <p className="color-red title-09">{errorMessage}</p>
            </div>
            )}
        </section>
        <section id="acciones">
            <Button type="primary" size="large" onClick={() => saveThisForm()}> Grabar </Button>
            <Button type="secondary" size="large" onClick={() => cleanThisForm()}> Nuevo </Button>
        </section>        
        {(itemSaveMode.itemImageKey !== '') ?
            <Imageupld itemImageKey = {itemSaveMode.itemImageKey} itemViewMode = {itemSaveMode.itemViewMode} confirmUpload = {confirmUpload} />
            :''
        }    

  </div>
  )
}

export default Item