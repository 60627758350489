import React, { useState, useEffect } from 'react';
import { Input, Button } from "../../common/html-elements";
import { Alert } from "../../common/html-accesories";
import { Spin } from "../../common/html-accesories";
import useFetch from "../../../hooks/useFetch";
import Navbar from '../navbar';
import './style.sass';

const Bussines = () => {

    const [showSpin, setShowSpin] = useState(true)

    const logn = JSON.parse(localStorage.getItem('logn'));

    let userCtrl = (logn?.user != undefined) ? logn?.user : '0';

    const [rspnBussines, rqstBussines] = useFetch(
        `buss/user/${userCtrl}`,
        undefined,
        'GET',
        true,
        () => {
        let rspnData = rspnBussines?.data[0];
        
        localStorage.setItem("buss",JSON.stringify(rspnData))
        
        setShowSpin(false);
    })
/*
    useEffect(() => {
        console.log('error: ', rspnBussines.error);
    }, [rspnBussines.error])
 */   
  return (
    <div>
        <Navbar/>
        {showSpin && (
            <Spin />
        )}
        
        <section id="titulo">
            <h2 className="title-06">Bienvenido</h2>
        </section>
  </div>
  )
}

export default Bussines