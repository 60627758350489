import { configureStore } from '@reduxjs/toolkit';
import shopCartReducer from './features/shopingCart-slice';
import productPackReducer from './features/productPack-slice';

export const store = configureStore({
  reducer: {
    productPack: productPackReducer,
    shopCart: shopCartReducer,
  },
});
