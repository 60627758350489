import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import "./style.sass";

const Spin = () => {
    let color = "#26b50e";

    return (
        <div id="spinContent">
            <p>
                <BeatLoader
                color={color}
                loading={true}
                //cssOverride={override}
                size={30}
                aria-label="Loading request"
                data-testid="loader"
                />
            </p>
        </div>
    )
}

export default Spin;