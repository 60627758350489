import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Itemlist from "./components/pages/itemList";
import Categorylist from './components/pages/categoryList'
import Category from './components/pages/category';
import Item from "./components/pages/item";
import MenuList from './components/pages/menuList';
import Login from './components/pages/login';
import ProtectedRoutes from './components/common/protected';
import Bussines from './components/pages/bussiness';
function App() {
  
  return (
    <Router>
      <div>
          
        <section>
          <div className="row">
            <Routes>
              <Route exact path="/" element={
                <ProtectedRoutes>
                  <Bussines />
                </ProtectedRoutes> 
              }/>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/products" element={<ProtectedRoutes>
                <Itemlist />
              </ProtectedRoutes> } />
              <Route exact path="/product" element={<ProtectedRoutes>
                <Item />
              </ProtectedRoutes>} />
              <Route exact path="/product/:itemId" element={<ProtectedRoutes>
                <Item />
              </ProtectedRoutes>} />
              <Route exact path="/categories" element={<ProtectedRoutes>
                <Categorylist />
              </ProtectedRoutes>} >
              </Route>
              <Route exact path="/category" element={<ProtectedRoutes>
                <Category />
              </ProtectedRoutes>} >
              </Route>
              <Route exact path="/category/:categoryId" element={<ProtectedRoutes>
                <Category />
              </ProtectedRoutes>} >
              </Route>
              <Route exact path="/menu/:owner" element={<MenuList />} />
              <Route exact path="/menu" element={<MenuList />} />
            </Routes>
          </div>
        </section>
      </div>
    </Router>
  );
}

export default App;
