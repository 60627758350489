import React from "react";
import { Link } from 'react-router-dom';
import "./style.sass"

function Navbar() {

  const bussInfo = JSON.parse(localStorage.getItem('buss'));
  const bussName = (bussInfo?.bussinesName != undefined) ? bussInfo?.bussinesName : '';

    return (
    <header>
        <h2 className="logo">{bussName}</h2>
        <input type="checkbox" id="check" ></input>
        <label htmlFor="check" className="mostrar-menu">
          &#8801;
        </label>
        <nav className="menu">
          <Link to="/">Inicio</Link>
          <Link to="/products">Productos</Link>
          <Link to="/categories">Categorias</Link>
          <Link to="/menu">Menu</Link>
          <label htmlFor="check" className='esconder-menu'>
            &#215;
          </label>
        </nav>
    </header>  
    )
}

export default Navbar;