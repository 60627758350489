import { createSlice } from '@reduxjs/toolkit';

const productPackSlice = createSlice ({
    name: 'prodPackSlice',
    initialState: [],
    reducers: {
        addItem: (state, action) => {
            state = [];
            state.push(action.payload);
        },
        cleanItems: (state) => {
            state = [];
        }
    }
});

export const { addItem, cleanItems} = productPackSlice.actions;
export default productPackSlice.reducer;