import React, { useState, useEffect } from 'react';
import { Alert } from "../../common/html-accesories";
import ItemlistElmn from "../itemListElmn"
import useFetch from "../../../hooks/useFetch";
import "./style.sass";
import { Input, Select, Button } from "../../common/html-elements";
import { Spin } from "../../common/html-accesories";
import Navbar from '../navbar';
import { useNavigate } from 'react-router-dom';

const Itemlist = () => {
    const [items, setitems] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const bussInfo = JSON.parse(localStorage.getItem('buss'));
    let bussCode = (bussInfo?.bussinesCode != undefined) ? bussInfo?.bussinesCode : '';
    const navigate = useNavigate();
    const [selectedCategoryOption, setSelectedCategoryOption] = useState({
        value: '',
        text: '—TODOS—',
    })
    const [searchData, setSearchData] = useState({
        category: '',
        phrase: ''
    })
    const [itemDisplay, setItemDisplay] = useState(
        {
            itemId: 0,
            itemOwner: bussCode,
            itemVisible: "Y"
        }
    )
    const [showSpin, setShowSpin] = useState(true)

    const [rspnGetItems, invokeGetItems] = useFetch(
        `item?owner=${bussCode}&page=1&category=${searchData.category}&search=${searchData.phrase}`,
        undefined,
        'GET',
        true,
        () => {
        
            let rspnData = rspnGetItems.data;
            setitems(
                rspnData[0]
            );
            
            setCategoryOptions(
                rspnData[1].map((item) => ({
                    value: item.categoryCode,
                    text: item.categoryName.toUpperCase(),
                }))
            );

            setShowSpin(false)
        }
    )
    
    const [rspnUpdateVisible, rqstUpdateVisible] = useFetch(
        `vsbl/item/${itemDisplay.itemId}`,
        itemDisplay,
        'PATCH',
        false,
        () => {

            let rspnData = rspnUpdateVisible.data;
            let rslt = rspnData.b_rtrn_rslt.split("-")[0]
            
            //if (rslt === "OK"){
                
            //}else if(rslt === "NO"){

            //}
        }
    )
    //== handle data section
    const handleChangeData = (name, value) => {
        setSearchData((prevItem) => ({ ...prevItem, [name]: value }));
    };

    const handleSelectOption = (name, dataValue) => {
        let {value, text} = dataValue;
        setSearchData((prevItem) => ({ ...prevItem, [name]: value }));        
    };
    const handleChangeDisplay = (itemCtrl, visible) => {
        setItemDisplay((prevDisplay) => ({...prevDisplay, 'itemId': itemCtrl, 'itemVisible': visible}))
    }
    useEffect(() => {
        rqstUpdateVisible();
    }, [itemDisplay])

    return (
        <>
        <Navbar/>
        {showSpin &&
            <Spin />
        }
        <section id="finder">
            <Select
            placeholder="Selecciona una categoría"
            options={categoryOptions}
            onSelect={option => handleSelectOption('category', {value: option.value, text: option.text})}
            selectedItem={selectedCategoryOption}
            />
            <Input
            placeholder="Buscar por nombre"
            value={searchData.phrase}
            maxLength={40}
            onChange={(text) => handleChangeData("phrase", text)}
            />
        </section>
        <section id="actions">
            <Button type="secondary" size="large" onClick={() => {
                setShowSpin(true);
                invokeGetItems();
                }}> Recargar </Button>
            <Button type="primary" size="large" onClick={() => {
                navigate('/product');
                }}> Agregar </Button>
        </section>  
        <section id="producto">
            {
                items.map((item) => (
                    <ItemlistElmn elmn={item} changeDisplay={(i,v) => handleChangeDisplay(i,v)} />
                ))
            }
        </section>
    </>
  )
}

export default Itemlist