import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import imgShopcart  from '../../assets/shopcart.png';
const ShopCartView = () => {
    const shopCart = useSelector((state) => state.shopCart);
    const [totalProducts, setTotalProducts] = useState(0)
    let productSet = [];
    let productsQuantity = 0;
    useEffect(() => {
      shopCart?.forEach((arry) => {
        productSet = arry;
        productsQuantity = 0;
        productSet.forEach((elmn) => {
          productsQuantity += elmn.quantity;
        })
        setTotalProducts(totalProducts + productsQuantity)
      });
    
    }, [shopCart])
    
  return (
    <div className='shopcart'>
        <a href=''>
          <img alt='' src={imgShopcart} />
          <b>{totalProducts}</b>
          </a>
    </div>
  )
}

export default ShopCartView