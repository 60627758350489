import React from 'react'
import { Navigate } from "react-router-dom";

function protectedRoutes({children}) {
  let logn = JSON.parse(localStorage.getItem('logn'));
  if(logn == null){    
    return <Navigate to="/login" />;
  }  
  if (!logn?.auth) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }
  
  return children;
  
}

export default protectedRoutes