import React, { useState, useEffect} from 'react';
import { Button } from '../common/html-elements';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../../redux/features/shopingCart-slice';
import { addItem } from '../../redux/features/productPack-slice';

let itemsIntoPack = [];
let productsList = [];

const ProdsIntoPack = () => {
  const dispatch = useDispatch();
  itemsIntoPack = useSelector((state) => state.productPack);
  productsList = useSelector((state) => state.shopCart);
  console.log('PP-itemsIntoPack', itemsIntoPack );
  console.log('PP-shopCart', productsList );
    const [totalProducts, setTotalProducts] = useState(0);

    let productSet = [];
    let productsQuantity = 0;
    useEffect(() => {
      console.log('BB-productsIntoPack: ', itemsIntoPack);
      //setTotalProducts(totalProducts + 1)
    }, [itemsIntoPack,productsList])
    const onClicked = () => {
      console.log('a-clicked: ');
      console.log('PPB-itemsIntoPack', itemsIntoPack );
      console.log('PPB-shopCart', productsList );
      let newProductsList = [];
      newProductsList.push({ 
          category: "abcd",itemId: "8", itemName: "SOPA", quantity: 1
      })
      dispatch(addProduct(newProductsList))
      console.log('b-clicked: ');
    }
    
  return (
    <section id='addproduct'>
        <Button type="order" size="medium" onClick={() => onClicked()} > Pedir </Button>
    </section>
  )
}

export default ProdsIntoPack