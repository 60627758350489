import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../common/html-elements'

const CategorylistElmn = ({elmn, changeDisplay}) => {
    const [active, setActive] = useState( (elmn.categorySituation === 'Conforme') ? true : false)
    
    const ChangeCheck = () => {
        let check = active;
        check = !check;
        setActive(check);
        if (typeof(changeDisplay) === 'function'){
            changeDisplay(elmn.categoryId, (check == true) ? 1 : 2 )
        }
    }

    return (
        
    <div key={elmn.categoryId}>
        <b>
            {elmn.categoryName}
        </b>
        <p>
            {`${elmn.categoryFeatures}`}
        </p>
        <div>
            <Checkbox checked={active} onChange={() => ChangeCheck()} />
            <Link to={{pathname: `/category/${elmn.categoryId}`}} >Detalle</Link>
        </div>
        
    </div>
  
   
  )
}

export default CategorylistElmn