import React, { useState, useEffect } from 'react';
import { Input, Select, Button } from "../../common/html-elements";
import { Alert } from "../../common/html-accesories";
import { inputValidator, parseMoney } from "../../../utils/utils";
import { Spin } from "../../common/html-accesories";
import useFetch from "../../../hooks/useFetch";
import { useParams } from 'react-router-dom';
import Navbar from '../navbar';
import { useNavigate } from 'react-router-dom';
import './style.sass';

let categoryGroups = [];
let itemImageBckt = 'https://fast-menu.s3.amazonaws.com/';

const Category = () => {
    const { categoryId } = useParams();
    const [categoryCtrl, setCategoryCtrl] = useState((categoryId != undefined) ? categoryId : 0);
    const bussInfo = JSON.parse(localStorage.getItem('buss'));
    const bussCode = (bussInfo?.bussinesCode != undefined) ? bussInfo?.bussinesCode : '';
    const navigate = useNavigate();
    const [categorySaveMode, setCategorySaveMode] = useState({
        apiRoute: "category",
        apiMethod: "POST",
    });
    const [categoryData, setCategoryData] = useState({
        categoryOwner: bussCode, categoryName: "", categoryFeatures: "", categoryImage:"", categoryType:"", categoryPrice: 0, categoryGroups: "" 
    })
    const [errorMessage, setErrorMessage] = useState("");
    //== alert section
    const [showAlert, setShowAlert] = useState({
        show: false,
        title: "",
        message: "",
        type: "",
      });
    
    const resetAlert = () => {
        setShowAlert({
          show: false,
          title: "",
          message: "",
          type: "",
        });
      };

    const resetMessage = (sec) =>
        setTimeout(() => {
        resetAlert();
    }, sec * 1000);
    //== fin alert section
    const [showSpin, setShowSpin] = useState(true)

    const [rspnGetCategory, rqstGetCategory] = useFetch(
        `category/${categoryCtrl}?owner=${bussCode}`,
        undefined,
        'GET',
        true,
        () => {
        
        let rspnData = rspnGetCategory.data;

        if(categoryCtrl > 0 && rspnData){
            rspnData = rspnData[0];
            
            setCategoryData(() => ({
                categoryOwner: bussCode,
                categoryName: rspnData.categoryName, categoryFeatures: rspnData.categoryFeatures, categoryImage: rspnData.categoryImage,
                categoryType: rspnData.categoryType, categoryPrice: rspnData.categoryPrice, categoryGroups: rspnData.categoryGroups 
            }));
            
            setCategorySaveMode({
                apiRoute:`category/${rspnData.categoryId}`,
                apiMethod: "PUT",
            })
        }
        setShowSpin(false)
        }
    )

    const [rspnSaveCategory, rqstSaveCategory] = useFetch(
        categorySaveMode.apiRoute,
        categoryData,
        categorySaveMode.apiMethod,
        false,
        () => {
            setShowSpin(false);

            let rspnData = rspnSaveCategory.data;
            let rslt = rspnData.b_rtrn_rslt.split("-")[0]
            let rsltItemCtrl = rspnData.b_rtrn_rslt.split("-")[1]
            if (rslt === "OK"){
                
                setCategoryCtrl(rsltItemCtrl);

                setCategorySaveMode({
                    apiRoute: "category/" + rspnData.b_rtrn_rslt.split("-")[1],
                    apiMethod: "PUT",
                })
                
                setShowAlert({
                    show: true,
                    title: "Registro de categorías",
                    message: categorySaveMode.apiRoute.indexOf("/") > 0 ? "Registro actualizado." : "Registro completado.",
                    type: "success",
                })
            }else if(rslt === "NO"){
                setShowAlert({
                    show: true,
                    title: "No se completó el proceso.",
                    message: rspnData.b_rtrn_rslt,//.split("-")[1],
                    type: "warning",
                })
            }
            
            resetMessage(5);
            
        }
    )
    
    const saveThisForm = () => {
        setShowSpin(true);
        rqstSaveCategory();
    }

    const cleanThisForm = () => {
        setCategoryData({
            categoryOwner: bussCode, categoryName: "", categoryFeatures: "", categoryImage:"", categoryType:"", categoryPrice:0, categoryGroups: "" 
        });
        setCategorySaveMode({
            apiRoute: "category",
            apiMethod: "POST",
        });
        navigate('/category')
    }
    
    const handlePressEnter = (e) => {
        console.log("handler");
        if (e.key === "Enter") {
            console.log("enter key");
        }
    };

    //== handle data section
    const handleChangeData = (name, value) => {
        setCategoryData((prevItem) => ({ ...prevItem, [name]: value }));
    };

  return (
    <div>
        <Navbar/>
        {showSpin && (
            <Spin />
        )}
        {showAlert.show && (
            <Alert
            title={showAlert.title}
            message={showAlert.message}
            type={showAlert.type}
            hasBg={true}
            position="mid-mid"
            handleClose={() => resetAlert()}
            />
        )}
        
        <section id="titulo">
            <h4 className="title-06">Detalle de la categoría</h4>
        </section>
        <section>
            <Input
            placeholder="Nombre"
            value={categoryData.categoryName}
            maxLength={40}
            onChange={(text) => handleChangeData("categoryName", text)}
            />
            <Input
            placeholder="Resumen"
            value={categoryData.categoryFeatures}
            maxLength={40}
            onChange={(text) => handleChangeData("categoryFeatures", text)}
            />
            <Input
            placeholder="Tipo (P = pack, S = Simple)"
            value={categoryData.categoryType}
            maxLength={300}
            onChange={(text) => handleChangeData("categoryType", text)}
            />
            <Input
            placeholder="Grupos"
            value={categoryData.categoryGroups}
            maxLength={100}
            onChange={(text) => handleChangeData("categoryGroups", text)}
            />
            <Input
            placeholder="Precio por pack"
            value={() => inputValidator('numberdecimal', categoryData.categoryPrice.toString())}
            //value={() => categoryData.categoryPrice}
            maxLength={5}
            onChange={(text) => handleChangeData("categoryPrice", inputValidator('numberdecimal',text))}
            />

            {errorMessage && (
            <div className="formulario_error">
                <p className="color-red title-09">{errorMessage}</p>
            </div>
            )}
        </section>
        <section id="acciones">
            <Button type="primary" size="large" onClick={() => saveThisForm()}> Grabar </Button>
            <Button type="secondary" size="large" onClick={() => cleanThisForm()}> Nuevo </Button>
        </section>        

  </div>
  )
}

export default Category