import React, {useState, useEffect} from 'react';
import Resizer from "react-image-file-resizer";
import useFetch from "../../hooks/useFetch";
import "./style.sass";
import { Button } from "../common/html-elements";

let itemImageBckt = 'https://fast-menu.s3.amazonaws.com/';

const Imageupld = ({itemImageKey, itemViewMode, confirmUpload}) => {
    const [itemImageFile, setItemImageFile] = useState(itemViewMode === 'N' ? `${itemImageBckt}fast-menu.jpg` : `${itemImageBckt}${itemImageKey}.jpeg`)
    const [selectedFile, setSelectedFile] = useState(null);

    const [itemData, setItemData] = useState({})

    const [rspnUploadImag, rqstUploadImag] = useFetch(
        'upld/imag',
        itemData,
        'POST',
        false,
        () => {

            let rspnData = rspnUploadImag.data.b_rtrn_rslt.split('|');
            
            if (rspnData[0] === 'OK'){
                if (typeof confirmUpload == 'function') {
                    confirmUpload();
                }
                //setItemImageFile(rspnData[1])
            }
        }
    )
    const blobFile= (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    })

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);

        blobFile(e.target.files[0])
        .then(dataUri => {
          setItemImageFile(`${dataUri}`);
        })
    }
    
    const uploadFile = async (file) => {
        const resizeFile = (compFile) =>
            new Promise((resolve) => {
            Resizer.imageFileResizer(
                compFile,
                500,
                500,
                "JPEG",
                100,
                0,
                (uri) => {
                resolve(uri);
                },
                //"blob"
                'base64'
            );
        });
      
        const resizedImage = await resizeFile(file);
        
        setItemData({
            imagData: resizedImage.replace('data:image/jpeg;base64,',''),
            fileKey: itemImageKey,
            fileRoot: `${itemImageBckt}${itemImageKey}.jpeg`
        })
    }

    useEffect(() => {
        if(itemData.imagData != undefined){
            rqstUploadImag();
        }
    }, [itemData.imagData])
    
    
    return <section id="uploader">
        
        {/* <img src={`${(currentImage != '') ? currentImage : itemImageRoot }`} alt="Literal"/> */}
        <img src={`${itemImageFile}`} alt='Fast menu'  />
        <div className='upload-container'>
            <label for="file-input">
                <div class="upload-button"> Seleccione una imagen</div>
            </label>
            <input type="file" id="file-input" onChange={handleFileInput}/>
        </div>
        <Button type="primary" size="large"  onClick={() => uploadFile(selectedFile)}> Subir la imagen </Button>

    </section>
}

export default Imageupld;