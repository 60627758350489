import React from 'react';
import MenulistElmn from '../menuListElmn';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../../../redux/features/shopingCart-slice';
import { addItem } from '../../../redux/features/productPack-slice';

let itemsIntoPack = [];
let productsList = [];

const MenuGroups = ({elmn, itms, type, zoom, addp}) => {
    //const [itemsIntoPack, setItemsIntoPack] = useState([])
    //let itemsIntoPack = [{categoryCode:"C001",group:1,itemId:6,itemName:"Ensalada de palta"}];
    const dispatch = useDispatch();
    itemsIntoPack = useSelector((state) => state.productPack);
    productsList = useSelector((state) => state.shopCart);
    console.log('shopCart', productsList );
    
    const selectProduct = (item) => {
        console.log('type: ', item);
        if(type == 'P') {
            let newItemsIntoPack = itemsIntoPack.filter((row) => row?.group != item?.itemGroupId);
            newItemsIntoPack.push({group: item?.itemGroupId, categoryCode: item.categoryCode, itemId: item?.itemId, itemName: item?.itemName})
            itemsIntoPack = newItemsIntoPack.sort((a, b) => a.group - b.group);
            dispatch(addItem(itemsIntoPack));
            console.log('itemsIntoPack F', itemsIntoPack);
        }else{
            
            let newProductsList = [];
            newProductsList.push({ 
                category: item?.categoryCode,itemId: item?.itemId, itemName: item?.itemName, quantity: 1
            })
            dispatch(addProduct(newProductsList))
        }

    }

    if (typeof addp == 'function'){
        console.log('y ahora?');
    }

    const handleOrderMenu = (e) => {
        console.log('o sea');
    }

return (
    (type == 'P') ?
    <>
    <section id="menugrupo">
        <div key={elmn.groupId}>
            <strong>
                {elmn.groupName}
            </strong>
        </div>
    </section>
    <section id="menuitem">
        {
            itms.filter(menu =>
                menu.itemGroupId == elmn.groupId
            ).map(item =>
                <MenulistElmn elmn={item} zoom={(t,i,d,x) => zoom(t,i,d,x)} selectProduct={(i) => selectProduct(i)} />
            )
        }
    </section>
    
    </>
    :
    <section id="menuitem">
        {
            itms.map(item =>
                <MenulistElmn elmn={item} zoom={(t,i,d,x) => zoom(t,i,d,x)} selectProduct={(i) => selectProduct(i)} />
            )
        }
    </section>
  )
}

export default MenuGroups