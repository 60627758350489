import React, { useState, useEffect } from 'react';
import './style.sass'

const MenulistElmnZoom = ({zoomDesc}) => {
    
    return (

    <div className="zoomview">
        <img alt="" width="290px" src={zoomDesc?.image}></img>
        <p>
            {
                (zoomDesc?.ingredients !== '') ?
                <>
                <b>Ingredientes</b> 
                <span>
                {zoomDesc?.ingredients}
                </span>
                </>
                :
                ''
            }
            {
                (zoomDesc?.description !== '') ?
                <>
                <b>Descripción</b> 
                <span>
                {zoomDesc?.description}
                </span>
                </>
                :
                ''
            }
        </p>
    </div>       
  )
}

export default MenulistElmnZoom