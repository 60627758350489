import React from "react";
import PropTypes from "prop-types";
import { icon_close_alert, icon_init_alert } from "../icon-alert";
import "./style.sass"

const Alert = ({
  type,
  position,
  hasBg,
  title,
  message,
  handleClose,
  className,
}) => {
  return (
    <div id="alertContent">
      <div className="alertbody">
         <div className="alert_left">{icon_init_alert[type]}</div>
          <div className="alert_mid">
            <p className={`title-${type}`}>
            <b>{title}</b>
            </p>
            {message && (
              <p className={`message-${type}`}>{message}</p>
            )}
        </div>
        <div className={`alert_right pointer ${type}`} onClick={handleClose}>
          {icon_close_alert[type]}
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error"]),
  position: PropTypes.oneOf([
    "top-left",
    "top-mid",
    "top-right",
    "mid-left",
    "mid-mid",
    "mid-right",
    "bottom-left",
    "bottom-mid",
    "bottom-right",
  ]),
  hasBg: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func,
  className: PropTypes.string,
};

Alert.defaultProps = {
  handleClose: () => {},
  title: "",
  message: "",
  className: "",
};

export default Alert;
