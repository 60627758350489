export const COLORS = {
  //blue
  BLUE : '#0000ff',
  BLUE_2: '#1919e6',
  BLUE_3: '#2e2ed2',
  BLUE_4: '#2424a8',
  //Grays
  GRAY : '#494F66',
  GRAY_2 : '#676F8F',
  GRAY_3 : '#A3ABCC',
  GRAY_4 : '#C5CBE0',
  GRAY_5 : '#E4E8F7',
  GRAY_6 : '#F0F2FA',
  GRAY_7 : '#F7F8FC',

  //Green
  GREEN : '#17DC0E',
  GREEN_2 : '#4FC949',
  GREEN_3 : '#22A91C',

  //Orange
  ORANGE : '#FFA733',
  ORANGE_2 : '#FFDCA9',
  ORANGE_3 : '#FFEFD8',
  //red
  RED : '#E40D2D',
  RED_1 : '#D1243E',
  RED_2 : '#EE2644',
  RED_3 : '#F7A8B4',
  RED_4 : '#FACED4',
  RED_5 : '#FBE1E4'
};

export const FONTS = {
  LATO : 'Lato, Arial, sans-serif',
  ROBOTO : 'Roboto'
};

export const breakpoints = {
  xs: '320px',
  sm: '360px',
  md: '768px',
  lg: '1024px',
  xl: '1360px'
};



