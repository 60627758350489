import React from 'react';
import { parseMoney,fillZeroLeft } from "../../../utils/utils"
import RadioButton from '../../common/html-elements/radio-button/'
import Button from '../../common/html-elements/button'

let imageBaseRoot = 'https://fast-menu.s3.amazonaws.com/';

const MenulistElmn = ({elmn, zoom, selectProduct}) => {
    const {ingredients,description} = elmn;
    
    const handleChange = (item) => {
        selectProduct(elmn);
    }
    
    return (
   <>
    <div key={elmn.itemId}>
        <img alt={elmn.itemName} width="80px" onClick={() => zoom(elmn.itemName, elmn.ingredients, elmn.description, elmn.itemId)} src={`${imageBaseRoot}P${fillZeroLeft(elmn.itemId,8)}.jpeg`}></img>
        <div className='itemText'>
            <b>
                {elmn.itemName}
            </b>
            <span>
                {elmn.features}
            </span>
        </div>
        <div className='itemPrice'>
            <strong>{(elmn.itemPrice < elmn.priceOff) ? parseMoney(elmn.itemPrice) : parseMoney(elmn.priceOff)}</strong>
            {
                (elmn.itemGroupId == 0) ?
                    <div className='orderitem'>
                        <Button type="order" size="small" onClick={() => handleChange()}> Pedir </Button>
                    </div>
                :
                    <RadioButton name={elmn.itemGroupId} id={elmn.itemId} onChange={() => handleChange(elmn)} />
            }
            
        </div>
    </div>
  
    </>
  )
}

export default MenulistElmn